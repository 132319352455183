'use client';

import { Loader } from '@/components/Common/Loader';
import { useContext, useEffect } from 'react';
import AuthContext from '@/context/AuthContext';
import { ROUTE_LOGOUT, ROUTE_DRIVE_LIST } from '@/utils/Constants';
import { useRouter } from 'next/navigation';

export default function Home() {
  const router = useRouter();
  const { isLoggedIn } = useContext(AuthContext);
  const isUserLoggedIn: any = isLoggedIn();
  useEffect(() => {
    if (isUserLoggedIn) {
      // set timeout to 200 ms
      setTimeout(() => {
        router.push(ROUTE_DRIVE_LIST);
      }, 200);
    } else {
      router.push(ROUTE_LOGOUT);
    }
  }, [isUserLoggedIn, router]);
  return (
    <main className="flex min-h-screen flex-col items-center justify-between p-4 md:p-24">
      <Loader isLoading={true}></Loader>
    </main>

  );
}
